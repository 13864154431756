var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 col-md-12 container-md justify-content-center"},[_c('CDataTable',{staticClass:"border",attrs:{"striped":false,"bordered":false,"small":true,"items":_vm.docsItem,"fields":_vm.accordionFields,"items-per-page":10,"sorter":false,"tableFilter":false,"pagination":""},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('h5',{staticClass:"text-center",style:('color:')},[_vm._v(" No Data Found "),_c('em',{staticClass:"fas fa-ban",style:({ color: '#e55353' })})])]},proxy:true},{key:"download",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('div',{staticClass:"d-flex"},[(item && item.link_to_doc)?_c('button',{staticClass:"btn icon hand-icon mb-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.fileDownload(item)}}},[_c('em',{staticClass:"icon fas fa-download"})]):_c('span',{staticClass:"ml-3"},[_vm._v("--")])])])]}},{key:"document_name",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[(item && item.link_to_doc)?_c('CButton',{staticClass:"btn-link mb-1 cursor-pointer px-0 text-left",on:{"click":function($event){return _vm.preview(item)}}},[_vm._v(" "+_vm._s(item.document_name)+" ")]):_c('span',{staticClass:"ml-2"},[_vm._v("--")])],1)]}},{key:"upload",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[(item.is_already_uploaded)?_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.alreadyUploadedData),function(data,index){return _c('div',{key:data.action_id,staticClass:"d-flex align-items-center"},[_c('a',{staticClass:"text-primary d-flex",staticStyle:{"cursor":"pointer","font-size":"12px"},on:{"click":function($event){return _vm.preview(data)}}},[_vm._v(" "+_vm._s(data.document_name)+" ")]),(!_vm.isOfferAccepted)?_c('button',{staticClass:"hand-icon action-button ml-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDocuments(data)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e(),(
                !_vm.isOfferAccepted &&
                item.expects_multi_documents &&
                index === item.alreadyUploadedData.length - 1 &&
                (_vm.uploadAction[item.document_action_id]
                  ? _vm.uploadAction[item.document_action_id].length === 0
                  : true)
              )?_c('CButton',{staticClass:"btn-link p-0 mx-1",attrs:{"title":"Add another document"},on:{"click":function($event){return _vm.addUploadAction(item.document_action_id)}}},[_c('i',{staticClass:"fa-solid fa-circle-plus fs-20"})]):_vm._e()],1)}),0):_vm._e(),_vm._l((_vm.uploadAction[item.document_action_id]),function(upload,index){return _c('div',{key:`actions-${index}`,staticClass:"d-flex align-items-center",class:{ 'mt-1': index > 0 }},[_c('input',{ref:`file-ref-${item.document_action_id}-${index}`,refInFor:true,staticClass:"upload-document",attrs:{"id":`upload-document-${item.document_action_id}-${index}`,"name":`upload-document-${item.document_action_id}-${index}`,"type":"file","accept":`.${item.allowed_extensions}`},on:{"change":function($event){return _vm.selectedFile($event, item)}}}),_c('label',{staticClass:"btn-primary px-3 btn-style mb-0 text-white font-weight-bolder",attrs:{"name":`upload-document-${item.document_action_id}-${index}`,"for":`upload-document-${item.document_action_id}-${index}`}},[_vm._v(" Upload ")]),_c('div',{staticClass:"mx-1"},[(
                item.document_type == 'Offer Contract - unsigned & signed'
              )?_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                placement: 'left',
                content: 'Upload Signed Offer for Acceptance',
                appendToBody: true,
              }),expression:"{\n                placement: 'left',\n                content: 'Upload Signed Offer for Acceptance',\n                appendToBody: true,\n              }"}],staticClass:"hinticon-multi-select my-1 cursor-pointer mx-0",attrs:{"name":"cil-info"}}):_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                placement: 'left',
                content: 'Please Upload this Documents as Requested',
                appendToBody: true,
              }),expression:"{\n                placement: 'left',\n                content: 'Please Upload this Documents as Requested',\n                appendToBody: true,\n              }"}],ref:`upload-document-${item.document_action_id}-${index}`,refInFor:true,staticClass:"hinticon-multi-select my-1 cursor-pointer mx-0",attrs:{"name":"cil-info"}})],1),(
              !_vm.isOfferAccepted &&
              item.expects_multi_documents &&
              !item.is_already_uploaded &&
              index === 0
            )?_c('CButton',{staticClass:"btn-link p-0 mx-1",attrs:{"title":"Add another document"},on:{"click":function($event){return _vm.addUploadAction(item.document_action_id)}}},[_c('i',{staticClass:"fa-solid fa-circle-plus fs-20"})]):_vm._e(),(index > 0 || item.is_already_uploaded)?_c('CButton',{staticClass:"btn-link p-0 mx-1",attrs:{"title":"Remove"},on:{"click":function($event){return _vm.removeUploadAction(item.document_action_id, index)}}},[_c('i',{staticClass:"fa-solid fa-circle-minus text-danger fs-20"})]):_vm._e()],1)})],2)]}}])}),(_vm.isPhotoUpload)?_c('div',[_c('CropperModal',{attrs:{"isShowPopup":_vm.isPhotoUpload,"fileData":_vm.fileData,"modalColor":"primary","buttonLabel":"Apply"},on:{"modalCallBack":_vm.cropperModalCallBack}})],1):_vm._e(),_c('PreviewModal',{attrs:{"modalTitle":_vm.confirmationModal.modalTitle,"modalColor":_vm.confirmationModal.modalColor,"isShowPopup":_vm.confirmationModal.isShowPopup,"buttons":_vm.confirmationModal.buttons,"modalCallBack":_vm.modalCallBack,"isPreview":_vm.confirmationModal.isPreview,"iframe":_vm.iframe,"size":_vm.confirmationModal.size,"closeOnBackdrop":_vm.confirmationModal.closeOnBackdrop}}),(_vm.deleteConfirmModal.isShowPopup)?_c('Modal',{attrs:{"modalTitle":_vm.deleteConfirmModal.modalTitle,"modalColor":_vm.deleteConfirmModal.modalColor,"modalContent":_vm.deleteConfirmModal.modalContent,"isShowPopup":_vm.deleteConfirmModal.isShowPopup,"buttons":_vm.deleteConfirmModal.buttons,"modalCallBack":_vm.deleteModalCallBack,"closeOnBackdrop":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }