<template>
  <div class="home col-lg-11 col-md-11 container-md justify-content-center">
    <CTabs
      variant="tabs"
      :active-tab="activeTab"
      class="mt-3"
      @update:activeTab="activeTab = $event"
    >
      <CTab :title="`Pre-Offer  (${preOfferJobs.length})`">
        <OfferAndOnBoardDocuments
          v-if="activeTab === 0"
          action_type="pre_offer"
          :actionDocuments="preOfferDocs"
        />
      </CTab>
      <CTab :title="`Offer  (${offerJobs.length})`">
        <OfferAndOnBoardDocuments v-if="activeTab === 1" action_type="offer"
          :actionDocuments="offerDocs" />
      </CTab>
      <CTab :title="`Licensing  (${licensingJobs.length})`">
        <OfferAndOnBoardDocuments
          v-if="activeTab == 2"
          action_type="licensing"
          :actionDocuments="licensingDocs"
        />
      </CTab>
      <CTab :title="`Visa  (${visaJobs.length})`">
        <OfferAndOnBoardDocuments v-if="activeTab === 3" action_type="visa" 
          :actionDocuments="visaDocs"/>
      </CTab>
      <CTab :title="`Joining  (${joiningJobs.length})`">
        <OfferAndOnBoardDocuments
          v-if="activeTab === 4"
          action_type="joining"
          :actionDocuments="joiningDocs"
        />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OfferAndOnBoardDocuments from "@/containers/CandidateProfile/OfferAndOnBoardDocuments";

export default {
  components: {
    OfferAndOnBoardDocuments,
  },
  props: {
    // offerIDs: {
    //   type: Set,
    //   default: new Set(),
    // },
    // onBoardIDs: {
    //   type: Set,
    //   default: new Set(),
    // },
    preOfferDocs: {
      type: Array,
      default: () => [],
    },
    offerDocs: {
      type: Array,
      default: () => [],
    },
    licensingDocs: {
      type: Array,
      default: () => [],
    },
    visaDocs: {
      type: Array,
      default: () => [],
    },
    joiningDocs: {
      type: Array,
      default: () => [],
    },
    preOfferJobs: {
      type: Array,
      default: () => [],
    },
    offerJobs: {
      type: Array,
      default: () => [],
    },
    licensingJobs: {
      type: Array,
      default: () => [],
    },
    visaJobs: {
      type: Array,
      default: () => [],
    },
    joiningJobs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters(["getOnboardDetailsforCandidate", "documentExtensions"]),
  },
  methods: {
    ...mapActions(["fetchDocumentExtension"]),
  },
  mounted() {
    if (!this.documentExtensions.length) this.fetchDocumentExtension();
  },
};
</script>
