<template>
  <div>
    <div class="desktop-view">
      <div
        class="
          border
          col-lg-11 col-md-11
          container-md
          justify-content-center
          mt-5
        "
      >
        <CDataTable
          :striped="true"
          :responsive="true"
          :small="true"
          :items="getItems"
          :fields="fields"
          :items-per-page="5"
          sorter
          pagination
        >
          <template #preview="{ item }">
            <td class="py-2">
              <a
                class="hand-icon"
                @click="previewJob(item)"
                :style="'cursor:pointer'"
              >
                <i class="fas fa-eye ml-3" v-c-tooltip="'Preview Job'"></i>
              </a>
            </td>
          </template>
          <template #apply="{ item }">
            <td class="py-2">
              <CButton
                square
                size="sm"
                class="btn btn-secondary text-primary mb-3"
                :disabled="item.is_rejected"
                :style="item.is_rejected ? 'cursor:default' : 'cursor:pointer'"
                @click="onWithdrawJob(item)"
                >Withdraw</CButton
              >
            </td>
          </template>
        </CDataTable>
      </div>
    </div>
    <div class="mobile-view">
      <div v-if="getItems" class="multi-records">
        <div
          class="top-border d-flex justify-content-between p-2"
          v-for="(item, index) in getItems"
          :key="index"
        >
          <CRow
            class="d-flex"
            style="justify-content: space-between; margin-left: 5px"
          >
            <div class="table col col-5">
              <p class="head">Job ID</p>
              <!-- <p class="data">{{ item.job_id }}</p> -->
              <p class="data">{{ item.job_display_uid }}</p>
            </div>
            <div class="table col col-5">
              <p class="head">Job title</p>
              <p class="data">
                {{ item.job_title }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Facility Name</p>
              <p class="data">
                {{ item.facility_name }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Location</p>
              <p class="data">
                {{ item.location }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Country</p>
              <p class="data">
                {{ item.country }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Level</p>
              <p class="data">
                {{ item.level }}
              </p>
            </div>
            <div class="table col col-10">
              <p class="head">comment</p>
              <p class="data">
                {{ item.comment }}
              </p>
            </div>
            <div class="table col col-5">
              <CButton color="primary" class="pl-8" @click="previewJob(item)">
                Preview
              </CButton>
            </div>
            <div class="table col col-5">
              <CButton
                v-if="!getItems[index].apply"
                color="primary"
                class="pl-8"
                @click="onApplyJob(item)"
              >
                Apply Now
              </CButton>
              <CButton
                v-else
                color="btn btn-secondary text-primary"
                class="pl-8"
                :disabled="item.is_rejected"
                @click="onWithdrawJob(item)"
              >
                Withdraw
              </CButton>
            </div>
          </CRow>
        </div>
        <div v-if="!getItems.length">
          <p
            class="pt-3 text-center"
            :style="{ background: '#cccccc', height: '50px' }"
          >
            No items <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
          </p>
        </div>
      </div>
    </div>
    <JobCardPreviewModal
      v-if="previewModal"
      :isShowPopup="previewModal"
      :onCancelPreview="onCancelPreview"
    />
    <CModal
      id="applyjobModal"
      :title="modalTitle"
      color="primary"
      centered:false
      @update:show="onShow"
      :show.sync="successModal"
      >{{ this.modalMessage }}</CModal
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
export default {
  name: "Appliedjobs",
  components: {
    JobCardPreviewModal,
  },
  data() {
    return {
      jobID: null,
      successModal: false,
      modalTitle: "",
      modalMessage: "",
      previewModal: false,
      sorter: { external: true, resetable: true },
      fields: [
        // { key: "job_id", label: "Job ID", _style: "width:7%" },
        { key: "job_display_uid", label: "Job ID", _style: "width:7%" },
        { key: "job_title", label: "Job Title", _style: "width:15%" },
        { key: "facility_name", label: "Facility Name", _style: "width:17%" },
        { key: "location", label: "Location", _style: "width:10%" },
        { key: "country", label: "Country", _style: "width:12%" },
        { key: "level", label: "Level", _style: "width:10%" },
        { key: "preview", label: "Preview", _style: "width:5%" },
        { key: "apply", label: "Withdraw Job", _style: "width:11%" },
        { key: "comment", label: "Comments", _style: "width:10%" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getCandidateAppliedJobs",
      "getRoleScopes",
      "candidateProfile",
    ]),
    getItems() {
      return this.getCandidateAppliedJobs?.length
        ? this.getCandidateAppliedJobs
            .filter((val) => val.application_id)
            .map((val) => ({
              job_id: val?.job_id || "--",
              job_display_uid: val?.job?.display_uid || "--",
              customer_id: val?.job?.customer_uid,
              job_title: val?.job?.job_title || "--",
              facility_name:
                val?.job?.parent_org_info_required &&
                val?.job?.parent_org_info.length
                  ? val?.job?.parent_org_info[0].name
                  : val?.job?.organisation_name ||
                    val?.job?.organisation.name ||
                    "--",
              location:
                val?.job?.location_name || val?.job?.location?.location || "--",
              country:
                val?.job?.country_name ||
                val?.job?.location?.country?.country_name ||
                "--",
              level: val?.job?.level_names
                ? val?.job?.level_names?.join(", ")
                : val?.level_names
                ? val?.level_names?.join(", ")
                : "--",
              apply: val?.application_id,
              is_rejected: val?.is_accepted == false || false,
              comment: !val?.is_accepted
                ? val?.is_accepted == false
                  ? "Application Unsuccessful"
                  : "--"
                : "Application Successful",
            }))
        : [];
    },
  },
  mounted() {
    this.fetchAppliedJobApplication();
  },
  methods: {
    ...mapActions([
      "fetchJobBenefitsAdvertDescByJobId",
      "fetchAppliedJobApplication",
      "fetchWithdrawnJobApplication",
      "applyJob",
      "withdrawJob",
      "fetchAppliedJobCount",
      "fetchFavoJobCount",
      "fetchWithdrawJobCount",
      "deleteCandidateTransaction",
    ]),
    previewJob(job) {
      if (this.getRoleScopes && this.getRoleScopes.length) {
        this.fetchJobBenefitsAdvertDescByJobId({ job_id: job?.job_id });
        this.previewModal = true;
      }
    },
    onWithdrawJob(job) {
      this.jobID = job?.job_id;
      this.successModal = true;
      this.modalTitle = "NOTIFICATION";
      this.modalMessage = `Are you sure you want to withdraw from this job ?`;
    },
    onCancelPreview() {
      this.previewModal = false;
    },
    onShow(e, value) {
      if (value.target.textContent === " OK ") {
        this.withdrawJob({ job_id: this.jobID }).then((res) => {
          const job_id = this.jobID;
          const { candidate_uid } = this.candidateProfile;
          this.deleteCandidateTransaction({ job_id, candidate_uid });
          this.fetchAppliedJobCount();
          this.fetchWithdrawJobCount();
          this.fetchWithdrawnJobApplication();
          this.fetchFavoJobCount();
        });
      } else {
        this.isApplyingToJob = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>
