<template>
  <div class="col-lg-12 col-md-12 container-md justify-content-center">
    <CDataTable
      :striped="false"
      :bordered="false"
      :small="true"
      :items="docsItem"
      :fields="accordionFields"
      :items-per-page="10"
      :sorter="false"
      :tableFilter="false"
      pagination
      class="border"
    >
      <template #no-items-view>
        <h5 class="text-center" :style="'color:'">
          No Data Found
          <em class="fas fa-ban" :style="{ color: '#e55353' }"></em>
        </h5>
      </template>
      <template #download="{ item }">
        <td class="py-2">
          <div class="d-flex">
            <button
              type="button"
              class="btn icon hand-icon mb-1"
              v-if="item && item.link_to_doc"
              @click="fileDownload(item)"
            >
              <em class="icon fas fa-download"></em>
            </button>
            <span v-else class="ml-3">--</span>
          </div>
        </td>
      </template>
      <template #document_name="{ item }">
        <td class="py-2">
          <CButton
            class="btn-link mb-1 cursor-pointer px-0 text-left"
            v-if="item && item.link_to_doc"
            @click="preview(item)"
          >
            {{ item.document_name }}
          </CButton>
          <span v-else class="ml-2">--</span>
        </td>
      </template>
      <template #upload="{ item }">
        <td class="py-2">
          <div class="d-flex flex-column" v-if="item.is_already_uploaded">
            <div
              class="d-flex align-items-center"
              v-for="(data, index) in item.alreadyUploadedData"
              :key="data.action_id"
            >
              <a
                class="text-primary d-flex"
                style="cursor: pointer; font-size: 12px"
                @click="preview(data)"
              >
                {{ data.document_name }}
              </a>
              <button
                v-if="!isOfferAccepted"
                type="button"
                class="hand-icon action-button ml-1"
                @click="deleteDocuments(data)"
              >
                <i class="fas fa-trash"></i>
              </button>
              <CButton
                class="btn-link p-0 mx-1"
                title="Add another document"
                v-if="
                  !isOfferAccepted &&
                  item.expects_multi_documents &&
                  index === item.alreadyUploadedData.length - 1 &&
                  (uploadAction[item.document_action_id]
                    ? uploadAction[item.document_action_id].length === 0
                    : true)
                "
                @click="addUploadAction(item.document_action_id)"
              >
                <i class="fa-solid fa-circle-plus fs-20"></i>
              </CButton>
            </div>
          </div>
          <div
            class="d-flex align-items-center"
            :class="{ 'mt-1': index > 0 }"
            v-for="(upload, index) in uploadAction[item.document_action_id]"
            :key="`actions-${index}`"
          >
            <input
              :id="`upload-document-${item.document_action_id}-${index}`"
              class="upload-document"
              :name="`upload-document-${item.document_action_id}-${index}`"
              type="file"
              :ref="`file-ref-${item.document_action_id}-${index}`"
              :accept="`.${item.allowed_extensions}`"
              @change="selectedFile($event, item)"
            />
            <label
              :name="`upload-document-${item.document_action_id}-${index}`"
              :for="`upload-document-${item.document_action_id}-${index}`"
              class="btn-primary px-3 btn-style mb-0 text-white font-weight-bolder"
            >
              Upload
            </label>
            <div class="mx-1">
              <CIcon
                v-if="
                  item.document_type == 'Offer Contract - unsigned & signed'
                "
                name="cil-info"
                class="hinticon-multi-select my-1 cursor-pointer mx-0"
                v-c-tooltip="{
                  placement: 'left',
                  content: 'Upload Signed Offer for Acceptance',
                  appendToBody: true,
                }"
              />
              <CIcon
                v-else
                :ref="`upload-document-${item.document_action_id}-${index}`"
                name="cil-info"
                class="hinticon-multi-select my-1 cursor-pointer mx-0"
                v-c-tooltip="{
                  placement: 'left',
                  content: 'Please Upload this Documents as Requested',
                  appendToBody: true,
                }"
              />
            </div>
            <CButton
              class="btn-link p-0 mx-1"
              title="Add another document"
              v-if="
                !isOfferAccepted &&
                item.expects_multi_documents &&
                !item.is_already_uploaded &&
                index === 0
              "
              @click="addUploadAction(item.document_action_id)"
            >
              <i class="fa-solid fa-circle-plus fs-20"></i>
            </CButton>
            <CButton
              class="btn-link p-0 mx-1"
              title="Remove"
              v-if="index > 0 || item.is_already_uploaded"
              @click="removeUploadAction(item.document_action_id, index)"
            >
              <i class="fa-solid fa-circle-minus text-danger fs-20"></i>
            </CButton>
          </div>
        </td>
      </template>
    </CDataTable>
    <div v-if="isPhotoUpload">
      <CropperModal
        :isShowPopup="isPhotoUpload"
        :fileData="fileData"
        modalColor="primary"
        buttonLabel="Apply"
        @modalCallBack="cropperModalCallBack"
      />
    </div>
    <PreviewModal
      :modalTitle="confirmationModal.modalTitle"
      :modalColor="confirmationModal.modalColor"
      :isShowPopup="confirmationModal.isShowPopup"
      :buttons="confirmationModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="confirmationModal.isPreview"
      :iframe="iframe"
      :size="confirmationModal.size"
      :closeOnBackdrop="confirmationModal.closeOnBackdrop"
    />
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
      v-if="deleteConfirmModal.isShowPopup"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import pdf from "vue-pdf";
import PreviewModal from "@/components/reusable/PreviewModal";
import Modal from "@/components/reusable/Modal";
import CropperModal from "@/components/reusable/CropperModal";
import { deepClone, CONSTANT as CONST } from "@/helpers/helper";
import Vue from "vue";
export default {
  name: "OfferAndOnBoardTable",
  props: {
    jobID: {
      type: Number,
      default: null,
    },
    action_type: {
      type: String,
      default: "",
    },
    isOfferAccepted: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: {},
    },
    actionDocuments: {
      type: Array,
      default: [],
    },
  },
  components: {
    PreviewModal,
    CropperModal,
    Modal,
  },
  data: () => ({
    accordionFields: [
      { key: "type_name", label: "Classification", _style: "width: 20%" },
      { key: "library_name", label: "Name", _style: "width: 18%" },
      { key: "comments", label: "Comment", _style: "width: 18%" },
      { key: "document_name", label: "File Name", _style: "width: 15%" },
      { key: "download", label: "Download", _style: "width: 8%" },
      { key: "upload", label: "Upload", _style: "width: 20%" },
    ],
    iframe: {
      src: "",
      loaded: false,
      isImg: false,
      pdf: {
        ispdf: false,
        src: "",
        numPages: undefined,
        addContentClasses: "",
      },
    },
    fileData: {},
    isPhotoUpload: false,
    confirmationModal: {
      modalColor: "primary",
      modalTitle: "Document Preview",
      isShowPopup: false,
      buttons: ["Ok"],
      isPreview: false,
      size: "lg",
      closeOnBackdrop: false,
    },
    payload: {},
    uploadedItemData: {},
    deleteConfirmModal: {
      modalColor: "danger",
      modalTitle: "NOTIFICATION",
      modalContent: "",
      isShowPopup: false,
      buttons: ["Cancel", "Confirm"],
      selectedData: {},
    },
    uploadAction: {},
  }),
  computed: {
    ...mapGetters([
      "getCandidateOffers",
      "getCandidateOnBoardDocs",
      "uploaded",
      "candidateProfile",
      "documentExtensions",
      "getCustomerTypeIDFromAccessToken"
    ]),
    commonFileExtention() {
      return (
        this.documentExtensions.map(({ label }) => `.${label.toLowerCase()}`) ||
        []
      ).join();
    },
    getDocs() {
      let data = this.actionDocuments || [];
      return data;
    },
    docsItem() {
      let alreadyUploadedFiles = this.getDocs.filter(
        (filter) =>
          filter.actioned_by == "candidate" && filter.previous_action_id
      );
      alreadyUploadedFiles = alreadyUploadedFiles?.map(
        (item) => item.previous_action_id
      );
      const r =
        this.getDocs
          .filter(
            (filter) =>
              filter.job_id == this.jobID && filter.actioned_by == "recruiter"
          )
          .map((val) => {
            if (
              val?.action_required &&
              !alreadyUploadedFiles?.includes(val.document_action_id)
            ) {
              Vue.set(this.uploadAction, val.document_action_id, [
                { fileName: "" },
              ]);
            }
            return {
              ...val,
              type_name:
                val?.document_library?.customer_document_type?.type_name ||
                val?.customer_document_type?.type_name,
              customer_document_type_id:
                val.document_library?.customer_document_type_id ||
                val.customer_document_type_id,
              document_name:
                val.document_name ||
                val.document_library?.organisation_document?.document_name ||
                "--",
              link_to_doc:
                val.link_to_doc ||
                val?.document_library?.organisation_document?.link_to_doc,
              allowed_extensions:
                val.document_ext?.document_ext?.toLowerCase() ||
                val.document_library?.organisation_document?.document_ext?.document_ext?.toLowerCase() ||
                this.commonFileExtention,
              is_already_uploaded: alreadyUploadedFiles?.includes(
                val.document_action_id
              )
                ? val.document_action_id
                : null,
              alreadyUploadedData: this.getDocumentByPreviousActionID(
                val.document_action_id
              ),
              library_name: val?.document_library?.library_name,
              expects_multi_documents:
                val?.document_library?.expects_multi_documents,
            };
          }) || [];
      return r;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "downloadDocument",
      "fetchOffersDocsForCandidate",
      "createDocumentActionFiles",
      "deleteDocumentAction",
      "fetchOnBoardDocsForCandidate",
      "fetchDocsForCandidate",
    ]),
    getDocumentByPreviousActionID(document_action_id) {
      return this.getDocs.filter(
        (item) => item.previous_action_id === document_action_id
      );
    },
    fileDownload(data) {
      let { link_to_doc, document_name } = data;
      let url = link_to_doc.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name });
      return false;
    },
    preview(data) {
      data = deepClone(data);
      if (
        data?.document_ext?.document_ext === "PNG" ||
        data?.document_ext?.document_ext === "JPG" ||
        data?.document_ext?.document_ext === "JPEG" ||
        data?.document_ext?.document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = "https://" + window.location.host + data.link_to_doc;
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (data?.document_ext?.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          "https://" + window.location.host + data.link_to_doc
        );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (
        data?.document_ext?.document_ext === "TXT" ||
        data?.document_ext?.document_ext === "EPS" ||
        data?.document_ext?.document_ext === "TIF" ||
        data?.document_ext?.document_ext === "TIFF" ||
        data?.document_ext?.document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.confirmationModal.isPreview = false;
        this.confirmationModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=https://" +
          window.location.host +
          data.link_to_doc;
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      }
    },
    modalCallBack() {
      this.confirmationModal.isPreview = false;
      this.confirmationModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    arrayToJoinByComma(d) {
      return d?.map((v) => "." + v).join(", ");
    },
    selectedFile(event, data) {
      this.uploadedItemData = data;
      const size = event.target.files[0]?.size;
      this.selectedFileRef = `file-ref-${data?.document_action_id}`;
      if (Math.round(size / (1024 * 1024)) <= 5) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = data.allowed_extensions;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.isPhotoUpload = true;
            this.fileData = file;
          } else {
            this.isPhotoUpload = false;
            this.handleFileUploads(file, data);
          }
        } else {
          let fileTypeError = Array.isArray(fileTypeString)
            ? this.arrayToJoinByComma(fileTypeString)
            : `.${fileTypeString}`;
          if (this.$refs[this.selectedFileRef])
            this.$refs[this.selectedFileRef].value = "";
          this.isPhotoUpload = false;
          this.showToast({
            class: "bg-danger text-white",
            message: `Allowed File Type ${fileTypeError}`,
          });
        }
      } else {
        if (this.$refs[this.selectedFileRef])
          this.$refs[this.selectedFileRef].value = "";
        this.isPhotoUpload = false;
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
      }
    },
    handleFileUploads(file, data) {
      this.payload = {
        ...this.payload,
        fileName: file.name,
        file: file,
        isPhotoUpload: false,
        job_id: this.uploadedItemData.job_id,
      };
      this.uploadDocument();
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        if (this.$refs[this.selectedFileRef])
          this.$refs[this.selectedFileRef].value = "";
        this.fileData = {};
        this.isPhotoUpload = false;
      } else {
        let { fileBlob, fileName } = data;
        this.payload = { ...this.payload, fileName };
        this.payload.file = fileBlob;
        this.uploadDocument();
      }
    },
    uploadDocument() {
      let {
        customer_uid,
        candidate_uid,
        organisation_id: org_id,
      } = this.candidateProfile;
      let organisation_id = this.item?.job?.organisation_uid;
      organisation_id = organisation_id ? organisation_id : org_id;
      if (this.payload && this.payload.file) {
        const document_action_id = this.uploadedItemData.document_action_id;
        let finalPayload = {
          action_type: this.action_type,
          actioned_by: "candidate",
          previous_action_id: this.uploadedItemData.document_action_id,
          customer_uid,
          candidate_uid,
          organisation_id,
          comments: this.uploadedItemData?.document_type || null,
          job_id: this.uploadedItemData?.job_id,
          customer_document_type_id:
            this.uploadedItemData?.customer_document_type_id,
          file: this.payload.file,
          file_name: this.payload.fileName,
        };
        this.createDocumentActionFiles({
          ...finalPayload,
        }).then(() => {
          if (this.$refs[this.selectedFileRef])
            this.$refs[this.selectedFileRef].value = "";
          this.fileData = {};
          this.payload = {};
          this.isPhotoUpload = false;
          this.fetchDocActionsForCandidate();
          Vue.set(this.uploadAction, document_action_id, []);
        });
      }
    },
    fetchDocActionsForCandidate() {
      const { customer_uid, candidate_uid, organisation_id } =
        this.candidateProfile;
      let payload = {
        customer_uid,
        candidate_uid,
      };
      if (this.getCustomerTypeIDFromAccessToken != CONST.CUSTOMER_TYPE.recruiter_agency && organisation_id)
        payload = {
          ...payload,
          organisation_id,
        };
      this.fetchDocsForCandidate({
        ...payload,
        action_type__in: ["pre_offer", "offer", "licensing", "visa", "joining"],
      });
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1].toLowerCase();
    },
    deleteDocuments(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${data.document_name}?`;
      this.deleteConfirmModal.selectedData = data;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        let document = this.deleteConfirmModal.selectedData;
        const document_action_id = document.document_action_id;
        let payload = {
          document_action_id,
        };
        let { organisation_id } = this.candidateProfile;
        if (!organisation_id)
          payload = { ...payload, organisation_id: this.item?.organisation_id };
        this.deleteDocumentAction(payload).then(() => {
          this.fetchDocActionsForCandidate();
        });
      }
      this.deleteConfirmModal.selectedData = {};
      this.deleteConfirmModal.isShowPopup = false;
    },
    addUploadAction(document_action_id) {
      let actions = [];
      if (this.uploadAction[document_action_id])
        actions = this.uploadAction[document_action_id];
      actions = [...actions, { fileName: "" }];
      this.$nextTick(() => {
        Vue.set(this.uploadAction, document_action_id, actions);
      });
    },
    removeUploadAction(document_action_id, index) {
      this.uploadAction[document_action_id].splice(index, 1);
    },
  },
  filters: {
    removeExtension(data) {
      if (data) return data.split(".").slice(0, -1).join(".");
      return "--";
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  // width: 14px;
  // height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.nowrap {
  white-space: nowrap;
}
table {
  border: 1px solid #e3e3e3;
}
thead {
  background-color: #fafafa;
}
td {
  color: #777777;
}
.clickable-row {
  cursor: pointer;
}
.clickable-row:hover {
  background-color: #f3f3f3;
}
.clickable-action {
  cursor: pointer;
}
.clickable-action:hover {
  text-decoration: underline;
}
.upload-document {
  display: none;
}
.btn-style {
  padding: 7px;
  cursor: pointer;
  border-radius: 7px;
}
.fs-20 {
  font-size: 20px !important;
}
</style>
