<template>
  <div class="col-lg-12 col-md-12 container-md justify-content-center">
    <CDataTable
      :striped="false"
      :bordered="false"
      :small="true"
      :items="getItems"
      :fields="displayFields"
      :items-per-page="10"
      :sorter="false"
      :tableFilter="false"
      pagination
      ref="offer-cand-tab"
    >
      <template #no-items-view>
        <h5 class="text-center" :style="'color:'">
          No Data Found
          <em class="fas fa-ban" :style="{ color: '#e55353' }"></em>
        </h5>
      </template>
      <template #hospital_name="{ item }">
        <td>
          <span>
            {{ item.customer_name }} | Job ID - {{ item.job.display_uid }}
            <span
              style="color: #e55353; font-weight: 600"
              v-if="
                action_type === 'offer' &&
                acceptedOfferJobs.includes(item.job_id)
              "
            >
              [Accepted]
            </span>
          </span>
        </td>
      </template>
      <template #action="{ item }">
        <td class="py-2">
          <div class="d-flex">
            <CButton
              v-show="collapseIndex == item.document_action_id"
              square
              class="mr-1 btn-outline-primary btn-sm"
              :name="`offer-upload-collapse-job-${item.job_id}`"
              @click="toggleDetails(item)"
              v-c-tooltip="{
                content: 'Collapse all',
              }"
            >
              <em class="fa fa-minus"></em>
            </CButton>
            <CButton
              v-show="collapseIndex != item.document_action_id"
              square
              class="mr-1 btn-outline-primary btn-sm"
              :name="`offer-upload-list-job-${item.job_id}`"
              @click="toggleDetails(item)"
              v-c-tooltip="{
                content: 'List all',
              }"
            >
              <em class="fa fa-plus"></em>
            </CButton>
          </div>
        </td>
      </template>

      <template #details="{ item }">
        <CCollapse
          :show="collapseIndex == item.document_action_id"
          :duration="0"
          class="p-2"
        >
          <DocsTable
            :jobID="item.job_id"
            :action_type="action_type"
            :isOfferAccepted="
              acceptedOfferJobs.includes(item.job_id) && action_type === 'offer'
            "
            :item="item"
            :actionDocuments="actionDocuments"
          />
          <div
            class="
              col-lg-11 col-md-11
              container-md
              d-flex
              justify-content-end
              p-2
            "
          >
            <CButton
              v-if="
                !acceptedOfferJobs.includes(item.job_id) &&
                action_type == 'offer'
              "
              class="btn-primary"
              title="Offer Acceptance"
              color="primary"
              @click="offerUploadCheck(item.job_id)"
            >
              Accept and Acknowledge
            </CButton>
          </div>
        </CCollapse>
      </template>
    </CDataTable>
    <CModal
      color="primary"
      :show.sync="notificationModal.isShowPopup"
      v-show="notificationModal.isShowPopup"
    >
      <template #header>
        <h5>{{ notificationModal.title }}</h5>
      </template>
      <div>
        {{ notificationModal.message }}
      </div>
      <template #footer>
        <div>
          <span v-for="button in notificationModal.buttons" :key="button">
            <CButton
              class="ml-2"
              :color="
                ['Cancel', 'No'].includes(button) ? 'secondary' : 'primary'
              "
              @click="notificationModalCallBack(button)"
            >
              {{ button }}
            </CButton>
          </span>
        </div>
      </template>
    </CModal>
    <CModal
      color="primary"
      :show.sync="commentsModal.isShowPopup"
      v-show="commentsModal.isShowPopup"
    >
      <template #header>
        <h5>{{ commentsModal.title }}</h5>
      </template>
      <div>
        <ValidationObserver ref="comments" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="comments">
            <CRow>
              <CCol lg="12">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Comment</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextAreaInput
                        name="comments"
                        :value="commentsModal.comments"
                        label="comments"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="commentModalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="commentModalCallBack(true)"
          >Submit</CButton
        >
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DocsTable from "@/containers/CandidateProfile/OfferAndOnBoardTable";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import Vue from "vue";

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  name: "OfferAndOnBoardDocuments",
  props: {
    action_type: {
      type: String,
      default: "offer",
    },
    actionDocuments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DocsTable,
    TextAreaInput,
  },
  data: () => ({
    collapseIndex: null,
    selectedJob: {},
    displayFields: [
      { key: "hospital_name", label: "Hospital Name", _style: "width: 70%" },
      { key: "action", label: "Action", _style: "width: 20%" },
    ],
    notificationModal: {
      isShowPopup: false,
      title: "NOTIFICATION",
      message: "",
      buttons: ["Ok"],
    },
    selectedOfferJobId: null,
    listedJobs: [],
    acceptedOfferJobs: [],
    commentsModal: {
      title: "",
      isShowPopup: false,
      status: null,
      comments: null,
    },
  }),
  computed: {
    ...mapGetters([
      "candidateProfile",
      "getCandidateOffers",
      "getCandidateOnBoardDocs",
    ]),
    getItems() {
      let duplicateCheck = [],
        data = this.actionDocuments || [];
      let items = data.filter((filter) => {
        if (duplicateCheck.includes(filter.job_id)) {
          return false;
        } else {
          duplicateCheck.push(filter.job_id);
          return true;
        }
      });
      this.listedJobs = duplicateCheck;
      return items || [];
    },
  },
  watch: {
    listedJobs() {
      this.acceptedOfferJobs = [];
      this.listedJobs?.forEach((element) => {
        this.getActionStatus(element);
      });
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "acceptOfferByCandidate",
      "fetchCandidateAndJobStatus",
    ]),
    handleInput(name, value) {
      Vue.set(this.commentsModal, name, value);
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    toggleDetails(item) {
      if (this.collapseIndex === item.document_action_id) {
        this.collapseIndex = null;
        this.tooltipHide();
        return;
      }
      this.collapseIndex = item.document_action_id;
      this.selectedJob = item;
      this.tooltipHide();
    },
    async getActionStatus(job_id) {
      let status = {};
      if (this.action_type == "offer") {
        status = {
          status_id__in: [58],
          sub_status_id__in: [59, 60],
          order_by: "-action_detail_id",
        };
      } else if (this.action_type == "licensing") return;
      let response = await this.fetchCandidateAndJobStatus({
        candidate_uid: this.candidateProfile.candidate_uid,
        job_id,
        ...status,
        action_id: 18,
      });
      let { data } = response;
      if (data?.length) {
        if (
          data[0].status_id === 58 &&
          data[0].sub_status_id === 59 &&
          !data?.completed_by
        )
          return;
        this.acceptedOfferJobs.push(job_id);
      }
    },
    offerUploadCheck(job_id) {
      if (!this.checkAllDocumentsUploaded(job_id)) {
        this.notificationModal.message = `Please Upload the documents for the acceptance!`;
        this.notificationModal.buttons = ["Ok"];
        this.notificationModal.isShowPopup = true;
      } else {
        this.acceptOffer(job_id);
      }
    },
    acceptOffer(job_id) {
      this.notificationModal.message =
        "Please confirm you have signed the required document and you are ready to accept this offer.";
      this.notificationModal.buttons = ["No", "Yes"];
      this.notificationModal.isShowPopup = true;
      this.selectedOfferJobId = job_id;
    },
    notificationModalCallBack(button) {
      if (button === "Yes") {
        if (this.action_type == "offer") {
          this.commentsModal.isShowPopup = true;
          this.commentsModal.status = {
            update_status: 58,
            update_sub_status: 60,
          };
          this.commentsModal.title = "Offer Acceptence";
          this.commentsModal.comments = "Offer Accepted";
        } else if (this.action_type == "licensing") {
          const uploadPayload = {
            payloadData: {
              job_id: this.selectedOfferJobId,
              candidate_uid: this.candidateProfile.candidate_uid,
              payload: { comments: "Onboard Accepted" },
              updateStatusSubStatus: {
                update_status: 62,
                update_sub_status: 63,
              },
            },
          };
          this.updateStatus(uploadPayload);
        }
      }
      this.notificationModal.isShowPopup = false;
    },
    checkAllDocumentsUploaded(job_id) {
      let documentsToBeUploaded = this.actionDocuments
        ?.filter(
          (filter) =>
            filter.action_type == this.action_type &&
            filter.actioned_by == "recruiter" &&
            filter.action_required &&
            filter.job_id == job_id
        )
        ?.map((val) => val.document_action_id);
      let alreadyUploadedFiles = this.actionDocuments
        ?.filter(
          (filter) =>
            filter.action_type == this.action_type &&
            filter.actioned_by == "candidate" &&
            filter.previous_action_id &&
            filter.job_id == job_id
        )
        ?.map((val) => val.previous_action_id);
      return documentsToBeUploaded.every((el) =>
        alreadyUploadedFiles.includes(el)
      );
    },
    async commentModalCallBack(action) {
      if (action) {
        const isValid = await this.$refs.comments.validate();
        if (!isValid) {
          this.showToast({
            class: "bg-danger text-white",
            message: "please fill mandatory fields!",
          });
          return;
        }
        const { comments, status } = this.commentsModal;
        const uploadPayload = {
          payloadData: {
            job_id: this.selectedOfferJobId,
            candidate_uid: this.candidateProfile.candidate_uid,
            payload: { comments },
          },
          updateStatusSubStatus: status,
        };
        this.updateStatus(uploadPayload);
      } else {
        this.commentsModal.isShowPopup = false;
      }
    },
    async updateStatus(data) {
      await this.acceptOfferByCandidate(data);
      this.getActionStatus(this.selectedOfferJobId);
      this.commentsModal.isShowPopup = false;
      this.commentsModal.status = null;
      this.commentsModal.comments = null;
    },
  },
};
</script>
