<template>
  <div class="container-md col-lg-11 col-md-10 pt-2 organisation-list">
    <div>
      <CRow class="m-0">
        <CCol md="12" sm="12" lg="12">
          <ul
            class="nav nav-bar nav-tabs"
            style="border-bottom:none;float: right;height: 24px;"
          >
            <li
              v-if="getCandidateEmailMessages.length"
              class="nav-item pt-2 font-style"
            >
              <span class="float-left">
                {{
                  itemFrom +
                    " - " +
                    recruiterItemTo +
                    " " +
                    "of" +
                    " " +
                    getEmailMessagesCount
                }}
              </span>
            </li>
            <li class="nav-item" v-if="getCandidateEmailMessages.length">
              <CButton
                :disabled="pageCount == 1 ? true : false"
                @click="previousPage"
              >
                <i class="fas fa-angle-left"></i>
              </CButton>
            </li>
            <li class="nav-item" v-if="getCandidateEmailMessages.length">
              <CButton
                class="pr-0"
                :disabled="
                  pageCount == emailMessagePageCount ||
                  emailMessagePageCount == 0
                    ? true
                    : false
                "
                @click="nextPage"
              >
                <i class="fas fa-angle-right"> </i>
              </CButton>
            </li>
          </ul>
        </CCol>
      </CRow>
    </div>
    <div>
      <CandidateInboxGrid
        :getBoundOnly="getCandidateEmailMessages"
        :currentTab="currentTab"
        @emailGridCallBack="inboxSearchTerm"
        @applyFilter="filterInbox"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import CandidateInboxGrid from "@/containers/Communication/EmailGrid";

export default {
  components: {
    CandidateInboxGrid,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
    candidate_uid: {
      type: Number,
      default: null,
    },
    customer_uid: {
      type: Number,
      default: null,
    },
    organisation_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentTab: "CandidateInbox",
      pageCount: 1,
      itemsPerPage: 15,
      itemFrom: 1,
      itemTo: 15,
      recruiterItemTo: null,
       filter: {
        read: null,
      },
    };
  },
  watch: {
    getCandidateEmailMessages() {
      this.recruiterItemTo =
        this.getCandidateEmailMessages.length <= 15
          ? this.getCandidateEmailMessages.length + this.itemTo
          : this.getCandidateEmailMessages.length;
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getCandidateEmailMessages",
      "noMoreDataFromEmailList",
      "getEmailMessagesCount",
    ]),
    ...mapMutations(["getEmailPagination"]),
    getUserName() {
      let firstname = this.getUser.first_name;
      let lastname = this.getUser.surname;

      if (firstname && lastname) {
        firstname = firstname
          .replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");

        lastname = lastname
          .replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");

        return firstname + " " + lastname;
      }
      return false;
    },
    emailMessagePageCount() {
      let totalCount = Math.ceil(this.getEmailMessagesCount / 15);
      return totalCount;
    },
  },
  methods: {
    ...mapActions([
      "updateReadMessage",
      "fetchCandidateEmailMessages",
      "fetchEmailMessagesCount",
      "fetchSearchTermEmailMessages",
      "fetchSearchTermEmailMessagesCount",
    ]),
    previousPage() {
      this.pageCount -= 1;
      this.$store.state.communication.getEmailPagination.skip -= 15;
      this.fetchCandidateEmailMessages({
        email: encodeURIComponent(this.email),
        sender: this.getUserName,
      });
      this.itemTo -= this.itemsPerPage;
      this.itemFrom -= this.itemsPerPage;
    },
    nextPage() {
      this.pageCount += 1;
      this.$store.state.communication.getEmailPagination.skip += 15;
      let direction = "I";
      this.fetchCandidateEmailMessages({
        email: encodeURIComponent(this.email),
        sender: this.getUserName,
        direction: direction,
      });
      this.itemFrom += this.itemsPerPage;
      this.itemTo += this.itemsPerPage;
    },
    inboxSearchTerm(data) {
      let direction = "O";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchSearchTermEmailMessagesCount({
        email: encodeURIComponent(this.email),
        direction: direction,
        searchTerm: data,
      });
      this.fetchSearchTermEmailMessages({
        email: encodeURIComponent(this.email),
        direction: direction,
        searchTerm: data,
      });
    },
     
    filterInbox(filter) {
      this.filter.read = filter;
      let direction = "O";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchCandidateEmailMessages({
        email: encodeURIComponent(this.email),
        direction: direction,
        read: filter,
      });
      this.fetchEmailMessagesCount({
       email: encodeURIComponent(this.email),
        direction: direction,
        read: filter,
      });
     
    },
  },
  mounted() {
    let direction = "O";
    this.$store.state.communication.getEmailPagination.skip = 0;
    if (this.getUserName) {
      this.fetchCandidateEmailMessages({
        email: encodeURIComponent(this.email),
        direction: direction,
      });
      this.fetchEmailMessagesCount({
        email: encodeURIComponent(this.email),
        direction: direction,
      });
    }
    this.pageCount = 1;
    this.itemFrom = 1;
    this.itemTo = 0;
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  // padding: 10px 0px 5px 10px;
  float: right;
  height: 39px;
}
.font-style {
  font-weight: 600;
  color: #4f5d73;
  font-size: 12px;
}
@media (max-width: 520px) {
  .nav-bar {
    float: none;
  }
  .nav-tabs {
    border-bottom: none;
  }
}
</style>
