<template>
  <div class="mb-3 mt-2 pt-1 candidate-schedule">
    <div class="container timeline-scrolling">
      <div v-if="schedule.length">
        <h6 class="text-muted">
          Total:
          <span class="text-primary">{{ schedule.length }}</span>
          <span class="mx-1">|</span>
          Scheduled:
          <span class="text-primary">{{ schedule | pending }}</span>
          <span class="mx-1">|</span>
          Interviewed:
          <span class="text-primary">{{ schedule | completed }}</span>
        </h6>
        <div v-if="pendingSchedule.length">
          <h6 class="text-primary">Scheduled:</h6>
          <CCard
            v-for="{
              actions: schedule,
              organisation_id,
              organisation_name,
            } in pendingSchedule"
            :key="organisation_id"
          >
            <CCardBody>
              <h6 class="text-muted">{{ organisation_name }}</h6>
              <div class="row mt-3">
                <div class="col-md-10">
                  <ul class="timeline pt-2">
                    <li
                      v-for="(data, index) in schedule"
                      :key="index"
                      @click="openReview(data, index)"
                      style="cursor: pointer"
                      :name="`scheduled-card-job-${data.job_id}`"
                    >
                      <time class="time">
                        <span>{{ formatDate(data.initiated_date) }}</span>
                        <span>{{ formatTime(data.initiated_date) }}</span>
                      </time>
                      <div class="icon bg-success">
                        <i class="fas fa-user-md" style="color: white"></i>
                      </div>
                      <div class="label">
                        <CRow>
                          <CCol md="8">
                            <CRow class="mt-2" name="job-info">
                              <CCol md="3" class="mb-1">
                                <h6 class="text-muted mb-0">Job</h6>
                              </CCol>
                              <CCol md="9">
                                <span class="px-1 desktop-view">:</span>
                                <span>
                                  <span name="job-title">{{
                                    data.job.job_title
                                  }}</span>
                                  |
                                  <!-- <span>{{ data.job_id }} </span> -->
                                  <span :name="`job-id-${data.job_id}`"
                                    >{{ data.job_display_uid }}
                                  </span>
                                </span>
                              </CCol>
                            </CRow>
                            <CRow class="mt-2" name="requested-by">
                              <CCol md="3">
                                <h6 class="text-muted">Requested By</h6>
                              </CCol>
                              <CCol md="9">
                                <span class="px-1 desktop-view">:</span>
                                <span>
                                  <span>{{
                                    data.initiated_by_user | getName
                                  }}</span>
                                  <div class="d-block pl-2 mt-1">
                                    <CBadge
                                      color="primary"
                                      shape="pill"
                                      v-if="data.status"
                                      >{{ data.status || "--" }}</CBadge
                                    >
                                    <CBadge
                                      color="success"
                                      shape="pill"
                                      v-if="data.sub_status"
                                      class="mx-1"
                                      >{{ data.sub_status || "--" }}</CBadge
                                    >
                                  </div>
                                </span>
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol md="4" class="text-center m-auto">
                            <span class="text-primary">Interview at</span>
                            <h6 class="text-primary">
                              {{ data.selected_slot_date | formatDate }} -
                              {{
                                convert2Date(
                                  data.selected_slot_date,
                                  data.selected_slot_start
                                ) | formatTime
                              }}
                            </h6>
                          </CCol>
                        </CRow>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </div>
        <div v-if="completedSchedule.length">
          <h6 class="text-primary">Interviewed:</h6>
          <CCard
            v-for="{
              actions: schedule,
              organisation_id,
              organisation_name,
            } in completedSchedule"
            :key="organisation_id"
          >
            <CCardBody>
              <h6 class="text-muted">{{ organisation_name }}</h6>
              <div class="row mt-3">
                <div class="col-md-10">
                  <ul class="timeline pt-2">
                    <li v-for="(data, index) in schedule" :key="index">
                      <time class="time">
                        <span>{{ formatDate(data.initiated_date) }}</span>
                        <span>{{ formatTime(data.initiated_date) }}</span>
                      </time>
                      <div class="icon bg-success">
                        <i class="fas fa-user-md" style="color: white"></i>
                      </div>
                      <div class="label">
                        <CRow>
                          <CCol md="8">
                            <CRow class="mt-2">
                              <CCol md="3" class="mb-1">
                                <h6 class="text-muted mb-0">Job</h6>
                              </CCol>
                              <CCol md="9">
                                <span class="px-1 desktop-view">:</span>
                                <span>
                                  <span>{{ data.job.job_title }}</span> |
                                  <!-- <span>{{ data.job_id }} </span> -->
                                  <span>{{ data.job_display_uid }} </span>
                                </span>
                              </CCol>
                            </CRow>
                            <CRow class="mt-2">
                              <CCol md="3">
                                <h6 class="text-muted">Requested By</h6>
                              </CCol>
                              <CCol md="9">
                                <span class="px-1 desktop-view">:</span>
                                <span>
                                  <span>{{
                                    data.initiated_by_user | getName
                                  }}</span>
                                  <div class="d-block pl-2 mt-1">
                                    <CBadge
                                      color="primary"
                                      shape="pill"
                                      v-if="data.status"
                                      >{{ data.status || "--" }}</CBadge
                                    >
                                    <CBadge
                                      color="success"
                                      shape="pill"
                                      v-if="data.sub_status"
                                      class="mx-1"
                                      >{{ data.sub_status || "--" }}</CBadge
                                    >
                                  </div>
                                </span>
                              </CCol>
                            </CRow>
                          </CCol>
                          <CCol md="4" class="text-center m-auto">
                            <span class="text-primary">Completed at</span>
                            <h6 class="text-primary fs-13">
                              {{ data.initiated_date | formatDate }}
                              {{ data.initiated_date | formatTime }}
                            </h6>
                          </CCol>
                        </CRow>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </div>
      </div>
      <p v-else class="text-muted text-gray p-2 h5 text-center">
        No Schedules Found!
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import m from "moment";
export default {
  props: {
    schedule: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pendingSchedule() {
      const res = _.chain(this.schedule)
        .filter((v) => v.status_id === 54 && v.sub_status_id === 55)
        .groupBy("organisation_id")
        .map((value, key) => ({
          organisation_id: key,
          organisation_name: value.length ? value[0]?.organisation_name : null,
          actions: value,
        }))
        .value();
      return res;
    },
    completedSchedule() {
      const res = _.chain(this.schedule)
        .filter((v) => v.status_id === 54 && [57, 47].includes(v.sub_status_id))
        .groupBy("organisation_id")
        .map((value, key) => ({
          organisation_id: key,
          organisation_name: value.length ? value[0]?.organisation_name : null,
          actions: value,
        }))
        .value();
      return res;
    },
  },
  methods: {
    ...mapActions(["jobDisplayStatus", "resetJobTransactions"]),
    convert2Date(date, time) {
      if(date && time)
        return m(date + " " + time, "YYYY-MM-DD HH:mm:ss");
      return null;
    },
    formatTime(data) {
      return (
        m
          .utc(data)
          .local()
          .format("hh:mm A") || "--"
      );
    },
    formatDate(data) {
      return (
        m
          .utc(data)
          .local()
          .format("Do MMM yyyy") || "--"
      );
    },
    openReview(data, index) {
      const { job_id, candidate_uid } = data;
      this.$router.push({
        path: `candidate-schedule-interview/job/${job_id}/candidate/${candidate_uid}`,
      });
    },
  },
  filters: {
    getName(data) {
      if (data) {
        return `${data?.first_name} ${data?.surname}` || "--";
      }
      return "--";
    },
    pending(data) {
      if (data)
        return data.filter((v) => v.status_id === 54 && v.sub_status_id === 55)
          .length;
      else return [].length;
    },
    completed(data) {
      if (data)
        return data.filter(
          (v) => v.status_id === 54 && [57, 47].includes(v.sub_status_id)
        ).length;
      else return [].length;
    },
    formatDate(data) {
      if (data)
        return m
          .utc(data)
          .local()
          .format("Do MMM yyyy");
      return "--";
    },
    formatTime(data) {
      if (data)
        return m
          .utc(data)
          .local()
          .format("hh:mm A");
      return "--";
    },
  },
};
</script>
