<template>
  <div class="col-lg-12 col-md-12 container-md justify-content-center">
    <CDataTable
      :striped="false"
      :bordered="false"
      :small="true"
      :items="getOfferItems"
      :fields="displayFields"
      :items-per-page="10"
      :sorter="false"
      :tableFilter="false"
      pagination
      ref="offer-cand-tab"
    >
      <template #no-items-view>
        <h5 class="text-center" :style="'color:'">
          No Data Found
          <em class="fas fa-ban" :style="{ color: '#e55353' }"></em>
        </h5>
      </template>
      <template #hospital_name="{ item }">
        <td>
          <span>
            {{ item.customer_name }} | Job ID - {{ item.job.display_uid }}
            <span
              style="color: #e55353; font-weight: 600"
              v-if="item.status_accepted"
            >
              [{{ item.status_accepted }}]
            </span>
          </span>
        </td>
      </template>
      <template #action="{ item }">
        <td class="py-2">
          <div class="d-flex">
            <CButton
              v-show="collapseIndex == item.document_action_id"
              square
              class="mr-1 btn-outline-primary btn-sm"
              :name="`offer-upload-collapse-job-${item.job_id}`"
              @click="toggleDetails(item)"
              v-c-tooltip="{
                content: 'Collapse all',
                appendToBody: true,
              }"
            >
              <em class="fa fa-minus"></em>
            </CButton>
            <CButton
              v-show="collapseIndex != item.document_action_id"
              square
              class="mr-1 btn-outline-primary btn-sm"
              :name="`offer-upload-list-job-${item.job_id}`"
              @click="toggleDetails(item)"
              v-c-tooltip="{
                content: 'List all',
                appendToBody: true,
              }"
            >
              <em class="fa fa-plus"></em>
            </CButton>
          </div>
        </td>
      </template>

      <template #details="{ item }">
        <CCollapse
          :show="collapseIndex == item.document_action_id"
          :duration="0"
          class="p-2"
        >
          <DocsTable :jobID="item.job_id" />
          <div
            class="col-lg-11 col-md-11 container-md d-flex justify-content-end p-2"
          >
            <CButton
              class="btn-primary"
              title="Offer Acceptance"
              color="primary"
              @click="offerUploadCheck(item.job_id)"
            >
              Accept and Acknowledge
            </CButton>
          </div>
        </CCollapse>
      </template>
    </CDataTable>
    <CModal
      color="primary"
      :show.sync="notificationModal.isShowPopup"
      v-show="notificationModal.isShowPopup"
    >
      <template #header>
        <h5>{{ notificationModal.title }}</h5>
      </template>
      <div>
        {{ notificationModal.message }}
      </div>
      <template #footer>
        <div>
          <span v-for="button in notificationModal.buttons" :key="button">
            <CButton
              class="ml-2"
              :color="button == 'Cancel' ? 'secondary' : 'primary'"
              @click="notificationModalCallBack(button)"
            >
              {{ button }}
            </CButton>
          </span>
        </div>
      </template>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DocsTable from "@/containers/CandidateProfile/OfferAndOnBoardTable";

export default {
  name: "OfferDocuments",
  props: {},
  components: {
    DocsTable,
  },
  data: () => ({
    collapseIndex: null,
    selectedJob: {},
    displayFields: [
      { key: "hospital_name", label: "Hospital Name", _style: "width: 70%" },
      { key: "action", label: "Action", _style: "width: 20%" },
    ],
    notificationModal: {
      isShowPopup: false,
      title: "NOTIFICATION",
      message: "",
      buttons: ["Ok"],
    },
    selectedOfferJobId: null,
  }),
  computed: {
    ...mapGetters(["candidateProfile", "getCandidateOffers"]),
    getOfferItems() {
      let duplicateCheck = [];
      let items = this.getCandidateOffers.filter((filter) => {
        if (duplicateCheck.includes(filter.job_id)) {
          return false;
        } else {
          duplicateCheck.push(filter.job_id);
          return true;
        }
      });
      return items || [];
    },
  },
  methods: {
    ...mapActions(["showToast", "acceptOfferByCandidate"]),
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    toggleDetails(item) {
      if (this.collapseIndex === item.document_action_id) {
        this.collapseIndex = null;
        this.tooltipHide();
        return;
      }
      this.collapseIndex = item.document_action_id;
      this.selectedJob = item;
      this.tooltipHide();
    },
    offerUploadCheck(job_id) {
      if (!this.checkAllDocumentsUploaded(job_id)) {
        this.notificationModal.message = `Please Upload the documents for the acceptance!`;
        this.notificationModal.buttons = ["Ok"];
        this.notificationModal.isShowPopup = true;
      } else {
        this.acceptOffer(job_id);
      }
    },
    acceptOffer(job_id) {
      this.notificationModal.message =
        "Please confirm you have signed the required document and you are ready to accept this offer.";
      this.notificationModal.buttons = ["No", "Yes"];
      this.notificationModal.isShowPopup = true;
      this.selectedOfferJobId = job_id;
    },
    notificationModalCallBack(button) {
      if (button === "Yes") {
        let status = {
          update_status: 58,
          update_sub_status: 60,
        };
        let uploadPayload = {};
        uploadPayload = {
          ...uploadPayload,
          payloadData: {
            job_id: this.selectedOfferJobId,
            candidate_uid: this.candidateProfile.candidate_uid,
            payload: { comments: "Accepted offer" },
          },
        };
        uploadPayload = { ...uploadPayload, updateStatusSubStatus: status };
        this.acceptOfferByCandidate(uploadPayload);
      }
      this.notificationModal.isShowPopup = false;
    },
    checkAllDocumentsUploaded(job_id) {
      let documentsToBeUploaded = this.getCandidateOffers
        ?.filter(
          (filter) =>
            filter.action_type == "offer" &&
            filter.actioned_by == "recruiter" &&
            filter.action_required &&
            filter.job_id == job_id
        )
        ?.map((val) => val.document_action_id);
      let alreadyUploadedFiles = this.getCandidateOffers
        ?.filter(
          (filter) =>
            filter.action_type == "offer" &&
            filter.actioned_by == "candidate" &&
            filter.previous_action_id &&
            filter.job_id == job_id
        )
        ?.map((val) => val.previous_action_id);
      return (
        documentsToBeUploaded.length === alreadyUploadedFiles.length &&
        documentsToBeUploaded.every((el) => alreadyUploadedFiles.includes(el))
      );
    },
  },
};
</script>
