<template>
  <div>
    <div class="desktop-view">
      <div
        class="
          border
          col-lg-11 col-md-11
          container-md
          justify-content-center
          mt-5
        "
      >
        <CDataTable
          :striped="true"
          :small="true"
          :responsive="true"
          :items="getItems"
          :fields="fields"
          :items-per-page="5"
          sorter
          pagination
        >
          <template #preview="{ item }">
            <td class="py-2">
              <a
                class="hand-icon"
                @click="previewJob(item)"
                :style="'cursor:pointer'"
              >
                <em class="fas fa-eye ml-3" v-c-tooltip="'Preview Job'"></em>
              </a>
            </td>
          </template>
          <template #apply="{ item, index }">
            <td
              class="py-2"
              v-if="
                !getItems[index].apply &&
                ![52, 54, 58, 59, 60, 62, 69].includes(item.status_id)
              "
            >
              <CButton
                square
                class="mr-1 btn-primary btn-sm"
                :name="`apply-job-${item.job_id}`"
                :disabled="disableJobApplyBtn && jobId == item.job_id"
                @click="onApplyJob(item)"
                >Apply Now
              </CButton>
            </td>
            <td class="py-2" v-else-if="getItems[index].apply">
              <CButton
                square
                class="btn btn-secondary btn-sm text-primary mb-3"
                :name="`withdraw-job-${item.job_id}`"
                @click="onWithdrawJob(item)"
                >Withdraw</CButton
              >
            </td>
            <td class="py-2" v-else>
              <p>In Progress</p>
            </td>
          </template>
        </CDataTable>
      </div>
    </div>
    <div class="mobile-view">
      <div v-if="getItems" class="multi-records">
        <div
          class="top-border justify-content-between p-2"
          v-for="(item, index) in getItems"
          :key="index"
        >
          <CRow
            class="d-flex"
            style="justify-content: space-between; margin-left: 5px"
          >
            <div class="table col col-5">
              <p class="head">Job ID</p>
              <p class="data">{{ item.job_display_uid }}</p>
            </div>
            <div class="table col col-5">
              <p class="head">Job title</p>
              <p class="data">
                {{ item.job_title }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Facility Name</p>
              <p class="data">
                {{ item.facility_name }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Location</p>
              <p class="data">
                {{ item.location }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Country</p>
              <p class="data">
                {{ item.country }}
              </p>
            </div>
            <div class="table col col-5">
              <p class="head">Level</p>
              <p class="data">
                {{ item.level }}
              </p>
            </div>
            <div class="table col col-5">
              <CButton color="primary" class="pl-8" @click="previewJob(item)">
                Preview
              </CButton>
            </div>
            <div class="table col col-5">
              <td
                v-if="
                  !getItems[index].apply &&
                  ![52, 54, 58, 59, 60, 62, 69].includes(item.status_id)
                "
              >
                <CButton
                  color="primary"
                  class="pl-8"
                  :name="`apply-job-${item.job_id}`"
                  :disabled="disableJobApplyBtn && jobId == item.job_id"
                  @click="onApplyJob(item)"
                >
                  Apply Now
                </CButton>
              </td>
              <td v-else-if="getItems[index].apply">
                <CButton
                  color="btn btn-secondary text-primary"
                  class="pl-8"
                  :name="`withdraw-job-${item.job_id}`"
                  @click="onWithdrawJob(item)"
                >
                  Withdraw
                </CButton>
              </td>
              <td class="pl-8" v-else>
                <p>In Progress</p>
              </td>
            </div>
          </CRow>
        </div>
        <div v-if="!getItems.length">
          <p
            class="pt-3 text-center"
            :style="{ background: '#cccccc', height: '50px' }"
          >
            No items <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
          </p>
        </div>
      </div>
    </div>
    <JobCardPreviewModal
      v-if="previewModal"
      :isShowPopup="previewModal"
      :onCancelPreview="onCancelPreview"
    />
    <CModal
      id="applyjobModal"
      :title="modalTitle"
      color="primary"
      centered:false
      @update:show="onShow"
      :show.sync="successModal"
      >{{ this.modalMessage }}</CModal
    >
    <PreviewPreScreening
      v-if="previewPreScreeningModal.isShowPopup"
      :isShowPopup="previewPreScreeningModal.isShowPopup"
      :jobInfo="previewPreScreeningModal.jobInfo"
      :preScreeningQuestions="previewPreScreeningModal.preScreeningQuestions"
      @modalCallBack="previewPreScreeningModalCallBack"
      :modalTitle="previewPreScreeningModal.modalTitle"
    />
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
import PreviewPreScreening from "@/components/reusable/PreviewPreScreening";
export default {
  name: "MatchedJobs",
  components: {
    JobCardPreviewModal,
    PreviewPreScreening,
  },
  data() {
    return {
      jobId: null,
      successModal: false,
      modalMessage: "",
      modalTitle: "",
      previewModal: false,
      isApplyingToJob: false,
      sorter: { external: true, resetable: true },
      fields: [
        // { key: "job_id", label: "Job ID", _style: "width:7%" },
        { key: "job_display_uid", label: "Job ID", _style: "width:7%" },
        { key: "job_title", label: "Job Title", _style: "width:20%" },
        { key: "facility_name", label: "Facility Name", _style: "width:20%" },
        { key: "location", label: "Location", _style: "width:10%" },
        { key: "country", label: "Country", _style: "width:10%" },
        { key: "level", label: "Level", _style: "width:10%" },
        { key: "preview", label: "Preview", _style: "width:7%" },
        { key: "apply", label: "Apply", _style: "width:10%" },
      ],
      disableJobApplyBtn: false,
      previewPreScreeningModal: {
        isShowPopup: false,
        preScreeningQuestions: [],
        jobInfo: null,
        modalTitle: "Please complete the following questions to assist your application",
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCandidateMatchedJobs",
      "getRoleScopes",
      "preScreeningQuestions",
      "candidateProfile",
      "getCustomerId",
      "getOrgIDFromAccessToken",
    ]),
    getItems() {
      return this.getCandidateMatchedJobs?.length
        ? this.getCandidateMatchedJobs.map((val) => ({
            job_id: val.job_id || "--",
            job_display_uid: val.job
              ? val.job.display_uid
              : val?.display_uid || "--",
            job_title: val.job?.job_title || val.job_title || "--",
            facility_name: val.job?.parent_org_info_required
              ? val.job?.parent_org_info[0].name
              : val.job?.organisation.name || val.organisation.name || "--",
            location:
              val?.job?.location.location || val.location.location || "--",
            country:
              val.job?.location.country.country_name ||
              val.location.country.country_name ||
              "--",
            level: val?.job?.level_names
              ? val?.job?.level_names?.join(", ")
              : val?.level_names
              ? val?.level_names?.join(", ")
              : "--",
            apply: val?.ids?.applied_id,
            published: val.job?.published || val.published,
            status_id: val.status_id,
          }))
        : [];
    },
  },
  mounted() {
    this.fetchCandidateMatchedJobs();
  },
  methods: {
    ...mapActions([
      "fetchCandidateMatchedJobs",
      "fetchAppliedJobApplication",
      "fetchWithdrawnJobApplication",
      "fetchJobBenefitsAdvertDescByJobId",
      "applyJob",
      "withdrawJob",
      "fetchAppliedJobCount",
      "fetchWithdrawJobCount",
      "getPreScreeningQuestions",
      "createCandidateTransaction",
      "showToast",
      "deleteCandidateTransaction",
    ]),
    previewJob(job) {
      if (this.getRoleScopes && this.getRoleScopes.length) {
        this.fetchJobBenefitsAdvertDescByJobId({ job_id: job?.job_id });
        this.previewModal = true;
      }
    },
    onCancelPreview() {
      this.previewModal = false;
    },
    onApplyJob(job) {
      const { job_id } = job;
      this.jobId = job?.job_id;
      this.successModal = true;
      this.isApplyingToJob = true;
      this.modalTitle = "NOTIFICATION";
      this.modalMessage = `Are you sure you want to apply for this job ?`;
      this.previewPreScreeningModal.jobInfo = job;
      this.$store.commit("RESET_PRE_SCREENING_QUESTIONS");
      this.getPreScreeningQuestions(job_id);
    },
    onWithdrawJob(job) {
      this.jobId = job?.job_id;
      this.successModal = true;
      this.modalTitle = "NOTIFICATION";
      this.modalMessage = `Are you sure you want to withdraw from this job ?`;
    },
    onShow(e, value) {
      if (value.target.textContent === " OK ") {
        this.disableJobApplyBtn = true;
        if (this.isApplyingToJob) {
          if (this.preScreeningQuestions.length) {
            this.previewPreScreeningModal.preScreeningQuestions =
              this.preScreeningQuestions;
            this.previewPreScreeningModal.isShowPopup = true;
          } else {
            this.applyingJob();
          }
          this.isApplyingToJob = false;
        } else {
          this.withdrawJob({ job_id: this.jobId }).then((res) => {
            const job_id = this.jobID;
            const { candidate_uid } = this.candidateProfile;
            this.deleteCandidateTransaction({ job_id, candidate_uid });
            this.fetchAppliedJobCount();
            this.fetchAppliedJobApplication();
            this.fetchWithdrawJobCount();
            this.fetchWithdrawnJobApplication();
            this.disableJobApplyBtn = false;
          });
        }
      } else {
        this.isApplyingToJob = false;
      }
    },
    applyingJob() {
      this.applyJob({ job_id: this.jobId }).then((res) => {
        this.fetchAppliedJobCount();
        this.fetchAppliedJobApplication();
        this.fetchWithdrawJobCount();
        this.fetchWithdrawnJobApplication();
        this.disableJobApplyBtn = false;
      });
    },
    previewPreScreeningModalCallBack(action, data) {
      if (action && data) {
        const payload = data.map((v) => {
          return {
            question_id: v?.question_id,
            customer_uid: this.getCustomerId,
            organisation_id: this.getOrgIDFromAccessToken,
            ...v.answer,
          };
        });
        const { job_id } = this.previewPreScreeningModal.jobInfo;
        const { candidate_uid } = this.candidateProfile;
        this.createCandidateTransaction({
          job_id,
          candidate_uid,
          payload,
        }).then((res) => {
          if (res.status == 200) {
            this.showToast({
              class: "bg-success text-white",
              message: "Pre Screening Answer Saved Successfully!",
            });
            this.applyingJob();
          }
          this.previewPreScreeningModal.isShowPopup = false;
          this.previewPreScreeningModal.jobInfo = null;
          this.previewPreScreeningModal.preScreeningQuestions = [];
        });
      } else {
        this.previewPreScreeningModal.isShowPopup = false;
        this.previewPreScreeningModal.jobInfo = null;
        this.previewPreScreeningModal.preScreeningQuestions = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>
